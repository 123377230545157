<nz-page-header>
  <nz-page-header-title>
    <nz-breadcrumb nz-page-header-breadcrumb>
      <nz-breadcrumb-item>
        <a routerLink="/persons">Personen</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>Lijst</nz-breadcrumb-item>
    </nz-breadcrumb>
    <div>Personen</div>
  </nz-page-header-title>
  <nz-page-header-extra>
    <button nz-button nzSize="large">Alarmeren</button>
    <button nz-button nzSize="large">Exporteren</button>
    <button nz-button nzType="primary" nzSize="large">
      Persoon toevoegen
      <i nz-icon nzType="fa:plus"></i>
    </button>
  </nz-page-header-extra>
</nz-page-header>
<nz-space nzSize="middle" nzDirection="vertical">
  <nz-space-item>
    <div nz-row nzJustify="space-between" nzAlign="bottom">
      <div nz-col>
        <nz-space nzAlign="center">
          <nz-space-item>
            <nz-input-group style="width: 300px" nzPrefixIcon="fa:search">
              <input type="text" nz-input placeholder="Zoeken op naam, graad, ..." />
            </nz-input-group>
          </nz-space-item>
          <nz-space-item>
            <button nz-button (click)="toggleSearch()">
              <i nz-icon nzType="fa:filter"></i>
              Uitgebreid zoeken
            </button>
          </nz-space-item>
        </nz-space>
      </div>
      <div nz-col>
        <button nz-button nzType="text" nz-dropdown nzTrigger="click" [nzDropdownMenu]="buttonMenu">
          Kolommen
          <i nz-icon nzType="fa:chevron-down"></i>
        </button>
        <nz-dropdown-menu #buttonMenu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>1st menu item</li>
            <li nz-menu-item>2nd menu item</li>
            <li nz-menu-item>3rd menu item</li>
            <li nz-menu-item nzDanger>4th danger item</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </nz-space-item>
  <nz-space-item *ngIf="openSearch">
    <nz-card>
      <form nz-form nzLayout="vertical">
        <nz-row [nzGutter]="12">
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="entiteit">Entiteit</nz-form-label>
              <nz-form-control>
                <nz-select id="entiteit" nzSuffixIcon="fa:chevron-down">
                  <nz-option nzValue="" nzLabel=""></nz-option>
                  <nz-option nzValue="Antwerpen" nzLabel="Antwerpen"></nz-option>
                  <nz-option nzValue="Gent" nzLabel="Gent"></nz-option>
                  <nz-option nzValue="Sint-Niklaas" nzLabel="Sint-Niklaas"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="kazerne">Kazerne</nz-form-label>
              <nz-form-control>
                <nz-select id="kazerne" nzSuffixIcon="fa:chevron-down">
                  <nz-option nzValue="" nzLabel=""></nz-option>
                  <nz-option nzValue="Antwerpen" nzLabel="Antwerpen"></nz-option>
                  <nz-option nzValue="Gent" nzLabel="Gent"></nz-option>
                  <nz-option nzValue="Sint-Niklaas" nzLabel="Sint-Niklaas"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="ploeg">Ploeg</nz-form-label>
              <nz-form-control>
                <nz-select id="ploeg" nzSuffixIcon="fa:chevron-down">
                  <nz-option nzValue="" nzLabel=""></nz-option>
                  <nz-option nzValue="Antwerpen" nzLabel="Antwerpen"></nz-option>
                  <nz-option nzValue="Gent" nzLabel="Gent"></nz-option>
                  <nz-option nzValue="Sint-Niklaas" nzLabel="Sint-Niklaas"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="id">Personeelsnummer</nz-form-label>
              <nz-form-control>
                <input nz-input id="id" />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="naam">Naam</nz-form-label>
              <nz-form-control>
                <input nz-input id="naam" />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="datum">Datum</nz-form-label>
              <nz-form-control>
                <nz-date-picker nzSuffixIcon="fa:calendar"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="interventie">Interventie</nz-form-label>
              <nz-form-control>
                <input nz-input id="interventie" />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="unit">Unit</nz-form-label>
              <nz-form-control>
                <input nz-input id="unit" />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="graad">Graad</nz-form-label>
              <nz-form-control>
                <input nz-input id="graad" />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="vaardigheid">Vaardigheid</nz-form-label>
              <nz-form-control>
                <input nz-input id="vaardigheid" />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="functie">Functie</nz-form-label>
              <nz-form-control>
                <input nz-input id="functie" />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label nzFor="onbeschikbaarheidscode">Onbeschikbaarheidscode</nz-form-label>
              <nz-form-control>
                <input nz-input id="onbeschikbaarheidscode" />
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
        <nz-space>
          <nz-space-item>
            <button nz-button nzType="primary" (click)="toggleSearch()">
              <i nz-icon nzType="fa:search"></i>
              Zoeken
            </button>
          </nz-space-item>
          <nz-space-item>
            <button nz-button (click)="toggleSearch()">
              <i nz-icon nzType="fa:save"></i>
              Zoeken en bewaren
            </button>
          </nz-space-item>
          <nz-space-item>
            <button nz-button nzDanger nzType="text">
              <i nz-icon nzType="fa:trash"></i>
              Verwijderen
            </button>
          </nz-space-item>
        </nz-space>
      </form>
    </nz-card>
  </nz-space-item>
  <nz-space-item>
    <nz-table #sortTable [nzData]="listOfData" nzTableLayout="fixed">
      <thead>
      <tr>
        <th nzWidth="50px"></th>
        <th *ngFor="let column of listOfColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
        <th nzWidth="100px"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of sortTable.data" (click)="onItemChecked(data.id, !setOfCheckedId.has(data.id))">
        <td
          [nzChecked]="setOfCheckedId.has(data.id)"
          (nzCheckedChange)="onItemChecked(data.id, $event)"
        ></td>
        <td>{{ data.id }}</td>
        <td>{{ data.naam }}</td>
        <td>{{ data.graad }}</td>
        <td>{{ data.entiteit }}</td>
        <td>{{ data.status }}</td>
        <td nzAlign="right">
          <a routerLink="/persons/edit" nz-button nzSize="small" nzType="text">
            <i nz-icon nzType="fa:edit"></i>
          </a>
          <a nz-button nzSize="small" nzType="text">
            <i nz-icon nzType="fa:trash"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </nz-space-item>
</nz-space>
