import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DemoBreadcrumbComponent } from './components/demo-breadcrumb/demo-breadcrumb.component';
import { DemoButtonsComponent } from './components/demo-buttons/demo-buttons.component';
import { DemoDividerComponent } from './components/demo-divider/demo-divider.component';
import { DemoDropdownComponent } from './components/demo-dropdown/demo-dropdown.component';
import { DemoIconsComponent } from './components/demo-icons/demo-icons.component';
import { DemoMenuComponent } from './components/demo-menu/demo-menu.component';
import { DemoPersonsSidebarComponent } from './components/demo-persons-sidebar/demo-persons-sidebar.component';
import { DemoComponent } from './pages/demo/demo.component';
import { PersonsListComponent } from './pages/persons-list/persons-list.component';
import { PersonsComponent } from './pages/persons/persons.component';
import { ThemeModule } from './theme/theme.module';
import { PersonsFormComponent } from './pages/persons-form/persons-form.component';
import { DemoAlertsComponent } from './components/demo-alerts/demo-alerts.component';
import { DemoSelectComponent } from './components/demo-select/demo-select.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    DemoComponent,
    DemoButtonsComponent,
    DemoDividerComponent,
    DemoBreadcrumbComponent,
    DemoDropdownComponent,
    DemoIconsComponent,
    DemoMenuComponent,
    DemoPersonsSidebarComponent,
    PersonsComponent,
    PersonsListComponent,
    PersonsFormComponent,
    DemoAlertsComponent,
    DemoSelectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NoopAnimationsModule,
    NzButtonModule,
    NzTypographyModule,
    NzDividerModule,
    NzBreadCrumbModule,
    NzDropDownModule,
    NzIconModule,
    NzMenuModule,
    NzLayoutModule,
    NzPageHeaderModule,
    ThemeModule,
    NzNoAnimationModule,
    NzTableModule,
    NzSpaceModule,
    NzGridModule,
    NzInputModule,
    NzCardModule,
    NzFormModule,
    NzSelectModule,
    NzDatePickerModule,
    NzTabsModule,
    NzAlertModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
