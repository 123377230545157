<form nz-form nzLayout="vertical">
  <nz-form-item>
    <nz-form-label nzRequired nzFor="multiple">Multiple Select</nz-form-label>
    <nz-form-control>
      <nz-select nzMode="multiple" id="multiple" nzPlaceHolder="Please select">
        <nz-option nzLabel="Item 1" nzValue="item1"></nz-option>
        <nz-option nzLabel="Item 2" nzValue="item2"></nz-option>
        <nz-option nzLabel="Item 3" nzValue="item3"></nz-option>
        <nz-option nzLabel="Item 4" nzValue="item4"></nz-option>
        <nz-option nzLabel="Item 5" nzValue="item5"></nz-option>
        <nz-option nzLabel="Item 6" nzValue="item6"></nz-option>
        <nz-option nzLabel="Item 7" nzValue="item7"></nz-option>
        <nz-option nzLabel="Item 8" nzValue="item8"></nz-option>
        <nz-option nzLabel="Item 9" nzValue="item9"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>
