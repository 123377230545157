import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogoComponent } from './components/logo/logo.component';


@NgModule({
  declarations: [
    LogoComponent
  ],
  exports: [
    LogoComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
  ]
})
export class ThemeModule {
}
