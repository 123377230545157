<h4 nz-typography>Menu</h4>
<ul nz-menu nzMode="inline" nzTheme="dark" [nzInlineCollapsed]="true">
  <li nz-menu-item nzSelected>
    <i nz-icon nzType="fa:tachometer-alt-fast"></i>
    <span>Dashboard</span>
  </li>
  <li nz-menu-item>
    <i nz-icon nzType="fa:users"></i>
    <span>Personen</span>
  </li>
  <li nz-menu-item>
    <i nz-icon nzType="fa:cars"></i>
    <span>Voertuigen</span>
  </li>
</ul>
<br>
<ul nz-menu nzMode="inline" nzTheme="dark" [nzInlineCollapsed]="false">
  <li nz-menu-item nzSelected>
    <i nz-icon nzType="fa:tachometer-alt-fast"></i>
    <span>Dashboard</span>
  </li>
  <li nz-menu-item>
    <i nz-icon nzType="fa:users"></i>
    <span>Personen</span>
  </li>
  <li nz-menu-item>
    <i nz-icon nzType="fa:cars"></i>
    <span>Voertuigen</span>
  </li>
</ul>
<br>
<ul nz-menu nzMode="inline" nzTheme="light" [nzInlineCollapsed]="true">
  <li nz-menu-item nzSelected>
    <i nz-icon nzType="fa:tachometer-alt-fast"></i>
    <span>Dashboard</span>
  </li>
  <li nz-menu-item>
    <i nz-icon nzType="fa:users"></i>
    <span>Personen</span>
  </li>
  <li nz-menu-item>
    <i nz-icon nzType="fa:cars"></i>
    <span>Voertuigen</span>
  </li>
</ul>
<br>
<ul nz-menu nzMode="inline" nzTheme="light" [nzInlineCollapsed]="false">
  <li nz-menu-item nzSelected>
    <i nz-icon nzType="fa:tachometer-alt-fast"></i>
    <span>Dashboard</span>
  </li>
  <li nz-menu-item>
    <i nz-icon nzType="fa:users"></i>
    <span>Personen</span>
  </li>
  <li nz-menu-item>
    <i nz-icon nzType="fa:cars"></i>
    <span>Voertuigen</span>
  </li>
</ul>
