import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-persons-form',
  templateUrl: './persons-form.component.html',
  styleUrls: ['./persons-form.component.less']
})
export class PersonsFormComponent implements OnInit {
  public isError = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goBack(): void {
    this.router.navigateByUrl('/persons');
  }

  onSubmit(): void {
    this.isError = true;
  }
}
