import { Component, OnInit } from '@angular/core';
import { random, name} from 'faker';

interface DataItem {
  id: number;
  naam: string;
  graad: string;
  entiteit: string;
  status: string;
}

@Component({
  selector: 'app-persons-list',
  templateUrl: './persons-list.component.html',
  styleUrls: ['./persons-list.component.less']
})
export class PersonsListComponent implements OnInit {
  listOfColumn = [
    {
      title: 'Personeelsnr.',
      compare: (a: DataItem, b: DataItem) => a.id - b.id,
    },
    {
      title: 'Naam',
      compare: (a: DataItem, b: DataItem) => a.naam.localeCompare(b.naam),
    },
    {
      title: 'Graad',
      compare: (a: DataItem, b: DataItem) => a.graad.localeCompare(b.graad),
    },
    {
      title: 'Entiteit',
      compare: (a: DataItem, b: DataItem) => a.entiteit.localeCompare(b.entiteit),
    },
    {
      title: 'Status',
      compare: (a: DataItem, b: DataItem) => a.status.localeCompare(b.status),
    }
  ];
  listOfData: DataItem[] = [...new Array(100)].map(() => ({
    status: ['AO&Z', 'Dagploeg', 'VAK', 'Andere ploeg/plaats'][random.number(3)],
    entiteit: ['Gent', 'Sint-Niklaas', 'Antwerpen', ''][random.number(3)],
    naam: name.firstName() + ' ' + name.lastName(),
    graad: ['Adjudant', 'Kapitein', ''][random.number(2)],
    id: random.number(),
  }));
  setOfCheckedId = new Set<number>();
  search = '';
  openSearch = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleSearch(): void {
    this.openSearch = !this.openSearch;
  }

  onItemChecked(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

}
