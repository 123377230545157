<nz-page-header class="site-page-header" nzTitle="Title" nzSubtitle="This is a subtitle"></nz-page-header>
<div class="demo">
  <div class="demo__item">
    <app-demo-icons></app-demo-icons>
  </div>
  <div class="demo__item">
    <app-demo-buttons></app-demo-buttons>
  </div>
  <div class="demo__item">
    <app-demo-divider></app-demo-divider>
  </div>
  <div class="demo__item">
    <app-demo-breadcrumb></app-demo-breadcrumb>
  </div>
  <div class="demo__item">
    <app-demo-dropdown></app-demo-dropdown>
  </div>
  <div class="demo__item">
    <app-demo-menu></app-demo-menu>
  </div>
  <div class="demo__item">
    <app-demo-alerts></app-demo-alerts>
  </div>
  <div class="demo__item">
    <app-demo-select></app-demo-select>
  </div>
</div>
