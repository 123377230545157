<h4 nz-typography>Dropdown</h4>
<a nz-dropdown [nzDropdownMenu]="menu">
  Hover me
  <i nz-icon nzType="fa:chevron-down"></i>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li nz-menu-item>1st menu item</li>
    <li nz-menu-item>2nd menu item</li>
    <li nz-menu-item>3rd menu item</li>
    <li nz-menu-item nzDanger>4th danger item</li>
  </ul>
</nz-dropdown-menu>
<br>
<br>
<button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="buttonMenu">
  Click Me
  <i nz-icon nzType="fa:chevron-down"></i>
</button>
<nz-dropdown-menu #buttonMenu="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li nz-menu-item>1st menu item</li>
    <li nz-menu-item>2nd menu item</li>
    <li nz-menu-item>3rd menu item</li>
    <li nz-menu-item nzDanger>4th danger item</li>
  </ul>
</nz-dropdown-menu>
