<nz-layout>
  <nz-sider nzTheme="light" nzCollapsible [nzTrigger]="subTrigger" [nzCollapsedWidth]="10" nzWidth="240px">
    <ng-template #subTrigger>
      <i nz-icon nzType="fa:chevron-left"></i>
    </ng-template>
    <ul nz-menu nzMode="inline">
      <li nz-menu-item nzSelected>
        <i nz-icon nzType="fa:tachometer-alt-fast"></i>
        <span>Dashboard</span>
      </li>
      <li nz-menu-item>
        <i nz-icon nzType="fa:users"></i>
        <span>Personen</span>
      </li>
      <li nz-menu-item>
        <i nz-icon nzType="fa:cars"></i>
        <span>Voertuigen</span>
      </li>
    </ul>
  </nz-sider>
  <nz-content>
    <router-outlet></router-outlet>
  </nz-content>
</nz-layout>
