<nz-page-header nzBackIcon="fa:arrow-left" (nzBack)="goBack()">
  <nz-page-header-title>
    <nz-breadcrumb nz-page-header-breadcrumb>
      <nz-breadcrumb-item>
        <a routerLink="/persons">Personen</a>
      </nz-breadcrumb-item>
      <nz-breadcrumb-item>Wijzig Persoon</nz-breadcrumb-item>
    </nz-breadcrumb>
    <div>Wijzig Persoon</div>
  </nz-page-header-title>
  <nz-page-header-footer>
    <nz-tabset [nzSelectedIndex]="0">
      <nz-tab nzTitle="Algemeen"></nz-tab>
      <nz-tab nzTitle="Locatie"></nz-tab>
      <nz-tab nzTitle="Tellers"></nz-tab>
      <nz-tab nzTitle="Communicatie"></nz-tab>
      <nz-tab nzTitle="Planningsgroep"></nz-tab>
      <nz-tab nzTitle="Vaardigheden"></nz-tab>
      <nz-tab nzTitle="Functies"></nz-tab>
      <nz-tab nzTitle="Graad"></nz-tab>
      <nz-tab nzTitle="Detacheer Filter"></nz-tab>
    </nz-tabset>
  </nz-page-header-footer>
</nz-page-header>
<nz-alert *ngIf="isError" nzIconType="fa:times-circle" nzType="error" nzMessage="Error" nzShowIcon
          nzDescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry."></nz-alert>
<nz-card>
  <form nz-form nzLayout="vertical" (ngSubmit)="onSubmit()">
    <h5 nzType="secondary" nz-typography>Persoonlijk</h5>
    <div nz-row [nzGutter]="24">
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="firstName">Voornaam</nz-form-label>
          <nz-form-control [nzValidateStatus]="isError ? 'error' : ''" [nzErrorTip]="isError ? 'Should be combination of numbers & alphabets' : ''">
            <input placeholder="Type hier" nz-input name="firstName" type="text" id="firstName">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="lastName">Achternaam</nz-form-label>
          <nz-form-control>
            <input placeholder="Type hier" nz-input name="lastName" type="text" id="lastName">
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="24">
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="dateOfBirth">Geboortedatum</nz-form-label>
          <nz-form-control>
            <nz-date-picker nzPlaceHolder="Selecteer datum" nzSuffixIcon="fa:calendar"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="socialSecurityNumber">Rijksregisternr.</nz-form-label>
          <nz-form-control>
            <input placeholder="Type hier" nz-input name="socialSecurityNumber" type="text" id="socialSecurityNumber">
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="24">
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="aanspreking">Aanspreking</nz-form-label>
          <nz-form-control>
            <nz-select nzPlaceHolder="Selecteer een waarde" id="aanspreking" nzSuffixIcon="fa:chevron-down">
              <nz-option nzValue="Dhr." nzLabel="Dhr."></nz-option>
              <nz-option nzValue="Mvr." nzLabel="Mvr."></nz-option>
              <nz-option nzValue="Anders" nzLabel="Anders"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="geslacht">Geslacht</nz-form-label>
          <nz-form-control>
            <nz-select nzPlaceHolder="Selecteer een waarde" id="geslacht" nzSuffixIcon="fa:chevron-down">
              <nz-option nzValue="M" nzLabel="M"></nz-option>
              <nz-option nzValue="V" nzLabel="V"></nz-option>
              <nz-option nzValue="X" nzLabel="X"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <h5 nzType="secondary" nz-typography>Professioneel</h5>
    <div nz-row [nzGutter]="24">
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="abiid">ABI ID</nz-form-label>
          <nz-form-control>
            <input placeholder="Type hier" nz-input name="abiid" type="text" id="abiid">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="number">Personeelsnummer</nz-form-label>
          <nz-form-control>
            <input placeholder="Type hier" nz-input name="number" type="text" id="number">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="zoneForceId">ZoneForce UID</nz-form-label>
          <nz-form-control>
            <input placeholder="Type hier" nz-input name="zoneForceId" type="text" id="zoneForceId">
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="24">
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="statut">Statut</nz-form-label>
          <nz-form-control>
            <nz-select nzPlaceHolder="Selecteer een waarde" id="status" nzSuffixIcon="fa:chevron-down">
              <nz-option nzValue="Dhr." nzLabel="Dhr."></nz-option>
              <nz-option nzValue="Mvr." nzLabel="Mvr."></nz-option>
              <nz-option nzValue="Anders" nzLabel="Anders"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="aangeworvenOp">Aangeworven op</nz-form-label>
          <nz-form-control>
            <nz-date-picker nzPlaceHolder="Selecteer datum" nzSuffixIcon="fa:calendar"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="5">
        <nz-form-item>
          <nz-form-label nzFor="korpsVerlatenOp">Korps verlaten op</nz-form-label>
          <nz-form-control>
            <nz-date-picker nzPlaceHolder="Selecteer datum" id="korpsVerlatenOp"
                            nzSuffixIcon="fa:calendar"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <nz-space>
      <nz-space-item>
        <button nz-button nzType="primary">
          <i nz-icon nzType="fa:save"></i>
          Bewaar
        </button>
      </nz-space-item>
      <nz-space-item>
        <button nz-button nzType="text">
          Annuleer
        </button>
      </nz-space-item>
    </nz-space>
  </form>
</nz-card>
