import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-icons',
  templateUrl: './demo-icons.component.html',
  styleUrls: ['./demo-icons.component.less']
})
export class DemoIconsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
