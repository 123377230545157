import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit{
  public hasSubMenu = true;

  constructor(private router: Router) {
  }

  public ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.hasSubMenu = val.url.includes('/persons');
        }
      });
  }
}
