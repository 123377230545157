<h4 nz-typography>Dividers</h4>
<div>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonne merninisti licere mihi ista probare, quae sunt a te dicta? Refert
    tamen, quo modo.
  </p>
  <nz-divider></nz-divider>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonne merninisti licere mihi ista probare, quae sunt a te dicta? Refert
    tamen, quo modo.
  </p>
  <nz-divider nzText="With Text"></nz-divider>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonne merninisti licere mihi ista probare, quae sunt a te dicta? Refert
    tamen, quo modo.
  </p>
  <nz-divider nzDashed [nzText]="text">
    <ng-template #text><i nz-icon nzType="fa:plus"></i> Add</ng-template>
  </nz-divider>

  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonne merninisti licere mihi ista probare, quae sunt a te dicta? Refert
    tamen, quo modo.
  </p>
</div>
