import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoComponent } from './pages/demo/demo.component';
import { PersonsFormComponent } from './pages/persons-form/persons-form.component';
import { PersonsListComponent } from './pages/persons-list/persons-list.component';
import { PersonsComponent } from './pages/persons/persons.component';

const routes: Routes = [
  { path: '', component: DemoComponent },
  {
    path: 'persons', component: PersonsComponent, children: [
      { path: '', component: PersonsListComponent },
      { path: 'edit', component: PersonsFormComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
