<nz-layout>
  <nz-sider [nzCollapsed]="hasSubMenu" [nzCollapsible]="!hasSubMenu" [nzTrigger]="mainTrigger" nzWidth="240px">
    <ng-template #mainTrigger>
      <i nz-icon nzType="fa:chevron-left"></i>
    </ng-template>
    <app-logo></app-logo>
    <nz-divider></nz-divider>
    <nav nz-menu nzMode="inline" nzTheme="dark">
      <a routerLink="/" nz-menu-item nzSelected>
        <i nz-icon nzType="fa:tachometer-alt-fast"></i>
        <span>Dashboard</span>
      </a>
      <a routerLink="/persons" nz-menu-item>
        <i nz-icon nzType="fa:users"></i>
        <span>Personen</span>
      </a>
      <a routerLink="/" nz-menu-item>
        <i nz-icon nzType="fa:cars"></i>
        <span>Voertuigen</span>
      </a>
      <a routerLink="/" nz-menu-item>
        <i nz-icon nzType="fa:siren-on"></i>
        <span>Interventies</span>
      </a>
      <a routerLink="/" nz-menu-item>
        <i nz-icon nzType="fa:cog"></i>
        <span>Parameters</span>
      </a>
      <a routerLink="/" nz-menu-item>
        <i nz-icon nzType="fa:search"></i>
        <span>Zoeken</span>
      </a>
      <nz-divider></nz-divider>
      <a routerLink="/" nz-menu-item>
        <i nz-icon nzType="fa:user"></i>
        <span>Profiel</span>
      </a>
      <a routerLink="/users" nz-menu-item>
        <i nz-icon nzType="fa:info-circle"></i>
        <span>Informatie</span>
      </a>
      <a routerLink="/" nz-menu-item>
        <i nz-icon nzType="fa:tools"></i>
        <span>Tools</span>
      </a>
    </nav>
  </nz-sider>
  <nz-content>
    <router-outlet></router-outlet>
  </nz-content>
</nz-layout>
